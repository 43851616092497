/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";
import MetaForm from "../../Forms/MetaForm";
import { useManagedOptionsHook } from "../../Forms/useManagedOptionsHook";
import { Loading } from "../../Helper/Loading";
import { STEPS } from "./PackingListUploadModal";
import { MetaItemType } from "./types";
import { getOptionLabel } from "../../utils";


export default function WrappedPreviewPackingList() {
    return <div className="px-4 py-5" css={css`background: #e7eff8;`}>
        <PreviewPackingList />
    </div>;
}

interface GrowerListProps {
    growrs_to_insert: Array<{ggn:string; label:string; country: string; fruit_type:string, supplier: string}>;
    tenantFormOptions: any;
}

const GrowerList = ({ growrs_to_insert, tenantFormOptions }:GrowerListProps) => {
    return (
        <div>
            <h4>These new growers will be created in the system</h4>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Country</th>
                        <th>GGN</th>
                        <th>Fruit Type</th>
                        <th>Supplier</th>
                    </tr>
                </thead>
                <tbody>
                    {growrs_to_insert.map((grower, index) => (
                        <tr key={index}>
                            <td>{grower.label}</td>
                            <td>{getOptionLabel(tenantFormOptions, "country", grower.country)}</td>
                            <td>{grower.ggn}</td>
                            <td>{getOptionLabel(tenantFormOptions, "fruit_type", grower.fruit_type)}</td>
                            <td>{getOptionLabel(tenantFormOptions, "supplier", grower.supplier)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


export function PreviewPackingList() {

    const [missingValueModal, setMissingValueModal] = useState<MetaItemType | boolean>(false);
    const step = useSelector((state: any) => state.packingLists.step);
    const import_data = useSelector((state: any) => state.packingLists.import_data);
    const growers_to_insert = useSelector((state: any) => state.packingLists.growers_to_insert);
    const isUpating = useSelector((state: any) => state.packingLists.isUpdating);
    const [showSubpallets, setShowSubpallets] = useState(false);
    const config = useConfig();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const tenantFormOptions = allOptions?.[config.tenant_id] || {};

    if (isUpating) {
        return <Loading></Loading>;
    }

    if (!import_data) {
        return null;
    }


    // * labels for the table header
    const children_labels = ["ID", "type", ...import_data.children[0].meta.map((i) => i.key)];
    // * the Data
    const getChildRow = (child) => {
        return [
            <td key={0}>{<LayerLabelItem label={child.label} />}</td>,
            <td key={1}>{child.type}</td>,
            ...child.meta.map((i) => <td key={i.key}><MetaItem meta={i} setMissingValueModal={(meta: MetaItemType) => setMissingValueModal(meta)} /></td>)];
    };


    const children = import_data.children.flatMap((i) => [getChildRow(i), ...(i.children && showSubpallets ? i.children.map((i) => getChildRow(i)) : [])]);
    const hasSubpallets = import_data.children.some((i) => i.children && i.children.length > 0);

    return (
        <div>
            {step === STEPS.PICK_FILE_AND_REVIEW_CONTEXT && <div className="">
                <div className="fs-5 fw-bold mb-3">{<LayerLabelItem label={import_data.label} />}<br></br>
                    <span className="text-muted fs-6">{import_data.layer_type.text}</span>
                </div>
                {import_data.meta.map((meta, index) => <div key={index} className="d-flex justify-content-between" css={css`max-width: 50rem;`} >
                    <div><b>{meta.key}</b></div>
                    <div><MetaItem meta={meta} setMissingValueModal={(meta: MetaItemType) => setMissingValueModal(meta)} /> </div>
                </div>)}
            </div>}
            {step === STEPS.REVIEW_CHILDS_AND_IMPORT && <div className="">
                <Table size="sm" className="mb-0" bordered responsive={true} borderless={true}>
                    <thead>
                        <tr>
                            {children_labels.map((item) => <th key={item}>{item}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {children.map((columns, rowIndex) => <tr key={rowIndex}>{columns}</tr>)}
                    </tbody>
                </Table>
                {hasSubpallets && <div className="mt-3">
                    <Button size="sm" color="light" onClick={() => setShowSubpallets((i) => !i)}>{showSubpallets ? "Hide" : "Show"} subpallets</Button>
                </div>}
            </div>
            }
            {
                step === STEPS.REVIEW_GROWERS
                        && <GrowerList growrs_to_insert={growers_to_insert || []} tenantFormOptions={tenantFormOptions} />
            }
            <MissingValueModal isOpen={missingValueModal !== false} toggle={() => setMissingValueModal(false)} meta={missingValueModal} />
        </div>
    );
}

function LayerLabelItem({ label }: { label: string }) {
    const existing_layers = useSelector((state: any) => state.packingLists.existing_layers);
    if (existing_layers && existing_layers[label]) {
        return <Link to={`/layer/${existing_layers[label]}`}>{label}</Link>;
    }
    return <span>{label}</span>;
}

function MetaItem({ meta, setMissingValueModal }: { meta: MetaItemType, setMissingValueModal: (meta: MetaItemType) => void }) {
    return <div className="d-flex flex-nowrap" >
        {meta.isManaged && !meta.isValid && <div className="text-warning pe-2" onClick={() => setMissingValueModal(meta)}>
            <FontAwesomeIcon icon={faCircleExclamation} />
        </div>}
        <div className="text-nowrap">{meta.label}</div>
    </div>;
}

const formatOptionLabel = ({ value, label }: { value: string, label: string }) => {
    if (!label) {
        return <span className="text-muted">Select option to view possible values</span>;
    }
    return (
        <div className="py-1">
            <div>{label}</div>
            {value !== label
                && <div>
                    <Badge size="sm" className="me-1">{value}</Badge>
                </div>
            }
        </div>
    );
};


function MissingValueModal({ isOpen, toggle, meta }: { isOpen: boolean, toggle: () => void, meta: MetaItemType | boolean }) {
    const [object, setObject] = useState<object>({});
    const config = useConfig();
    const key = (meta && typeof meta === "object" && meta.key) || "";
    const options = useManagedOptionsHook({ object, name: key });
    const selectedOption = options.find((i) => object[key] && i.value === object[key]);


    const form = [typeof meta === "object"
        && {
            label: meta.key,
            name: meta.key,
            type: "single-select",
            options,
            formatOptionLabel
        },
    ];

    return <div>
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Meta value missing</ModalHeader>
            <ModalBody>
                {typeof meta === "object"
                    && <div>
                        <p>{meta.key} is a managed field. Meaning that the options are predefined to improve usability of EYE. You have three options</p>
                        <ol>
                            <li><b>Do nothing.</b> The value will be imported as it is.</li>
                            <li><b>Ask support to add an option</b> for {meta.value} on field {meta.key}.</li>
                            <li><b>Update your import file</b> and change the field to one of the predefined values. You can search for possible values below.</li>
                        </ol>
                        <MetaForm meta={form} setValue={(field, value) => setObject((query) => ({ ...query, [field]: value }))} object={object} config={config} />
                        {selectedOption && selectedOption.label !== selectedOption.value && <div className="mt-3">For this option use <b>{selectedOption.label}</b> or <b>{selectedOption.value}</b> in your packing list.</div>}
                    </div>}

            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    </div>;
}

