/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { Badge, Container, Table } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { PaginationRow } from "../../Helper/PaginationRow";
import { getNotifications, updateFilter } from "../../../actions/Mailbox/actions";
import { Loading } from "../../Helper/Loading";
import { formatDateTime } from "../Layers/fields/formatters";
import AttachmentLink from "../../Helper/AttachmentLink";
import { RootState } from "../../../reducers";
import { MailboxState } from "../../../actions/Mailbox/reducer";
import { MailRecipients } from "../Helpers";

const MailStatus = {
    New: "new",
    InProgress: "in_progress",
    Sent: "sent",
    Failed: "failed",
    Retry: "retry"
};

const BadgeColors = {
    [MailStatus.New]: "primary",
    [MailStatus.InProgress]: "warning",
    [MailStatus.Sent]: "success",
    [MailStatus.Failed]: "danger",
};

const getBadgeColor = (status: string) => {
    const color = BadgeColors[status];
    return color || "secondary";
};

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const StatusBadge = ({ status }) => {
    status = status === MailStatus.Retry ? MailStatus.InProgress : status;
    const badgeColor = getBadgeColor(status);
    const statusToPrint = capitalize(status.replace("_", " "));
    return <Badge color={badgeColor}>{statusToPrint}</Badge>;
};

const Mailbox = () => {
    const dispatch = useDispatch();
    const items = useSelector((state: any) => state.mailbox.items);
    const isLoading = useSelector((state: any) => state.mailbox.isLoading);
    const filter = useSelector<RootState, MailboxState["filter"]>((state) => state.mailbox.filter);

    useEffect(() => {
        dispatch(getNotifications(filter));
    }, [filter]);

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(updateFilter({ ...filter, offset }));
        }
    };

    const updateLimit = (limit) => {
        dispatch(updateFilter({ ...filter, limit }));
    };

    return <Container className="py-md-5 py-3">
        <div className="pt-4 d-flex flex-column" css={css`min-height: 50vh;`} >
            {isLoading && <Loading />}
            {!isLoading
                && <Table size="sm" responsive={true} borderless={true}>
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Layer</th>
                            <th>Supplier</th>
                            <th>Report</th>
                            <th>Recipients</th>
                            <th>Status</th>
                            <th>Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.results.map((item) => {
                            const { supplier, layer } = item.attributes;
                            return <tr key={item.id}>
                                <td>{item.subject}</td>
                                <td><Link to={`/layer/${layer.id}`}>{layer.label}</Link></td>
                                <td>{supplier.name}</td>
                                <td>
                                    {item.attachment_urls.map((url, i) => <AttachmentLink key={i} url={url} />)}
                                </td>
                                <td><MailRecipients recipients={item.recipients} /></td>
                                <td><StatusBadge status={item.status} /></td>
                                <td>{formatDateTime(dayjs(item.created))}</td>
                            </tr>;
                        })}
                    </tbody>
                </Table>
            }
        </div>
        <PaginationRow results={items.results} totalCount={items.count} filter={filter} onPageChanged={onPageChanged} updateLimit={updateLimit} />
    </Container>;
};


export default Mailbox;
