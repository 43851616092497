/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { css } from "@emotion/react";
import { PDFViewer, StyleSheet, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Container
} from "reactstrap";
import { AnyAction } from "redux";
import {
    getLayer,
    getLayerChildren,
    resetLayerState
} from "../../../../../actions/Layers/actions";
import useConfig, { useTenantFormOptions } from "../../../../../actions/Tenants/config/configHook";
import { device_image_types } from "../../../AddItem/components/Images";
import { getFruitTableData } from "../../../AddItem/components/Summary/FruitTable";
import { PDFCheckFruitTables } from "./PDFCheckFruitTables";
import { PDFImages } from "./PDFImages";
import { PDFDocument, PDFPage } from "./PDFLayout";

import { getCheck } from "../../../../../actions/Checks/actions";
import { getFilledArrayOrDefault } from "../../../../../utils";
import useAvosApi from "../../../../../utils/useAvosApiHook";
import { useDefectsHook } from "../../../../Forms/useManagedOptionsHook";
import { Loading } from "../../../../Helper/Loading";
import LoadingProgressBar from "../../../../Helper/LoadingProgressBar";
import webpToJpgBase64 from "../../../image-utils";
import { SendReportButton } from "../SendReportButton";
import { PDFCountDownText } from "./PDFIntakeReport";
import PDFLayerMeta from "./PDFLayerMeta";

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        height: "70vh"
    },
    cell1: {
        width: "40%", // Adjust based on your layout
        paddingTop: "15px"

    },
    cell2: {
        width: "56%", // Adjust based on your layout
        padding: "30px 0px"
    },

});

export default function PDFCheckReport() {
    const config = useConfig();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.auth.user);
    const layer = useSelector((state: any) => state.layers.current);
    const children = useSelector((state: any) => state.layers.children);
    const tenantsIsLoading = useSelector((state: any) => state.tenants.isLoading);
    const form_options = useTenantFormOptions();
    const check = useSelector((state: any) => state.checks.current);
    const [printCheck, setPrintCheck] = useState<any>();

    const params = useParams();
    const layer_config = config.get_layer_config(layer);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(resetLayerState());
        dispatch(getLayer(params.layer_id!) as unknown as AnyAction);
        dispatch(getLayerChildren(params.layer_id) as any);
    }, [params.layer_id]);

    useEffect(() => {
        dispatch(getCheck(params.test_id) as any);
    }, [params.test_id]);

    // when you navigate from a parent layer to a child layer check summary...
    // we need to give it some time to reinstate the config (e.g. when the parent doesn't have a location)
    const location = config.get_location(layer, check);
    const all_defects = useDefectsHook(layer.fruit_type);

    useEffect(() => {
        if (!layer || !check || (all_defects?.length || 0) === 0 || String(check?.test_id) !== params.test_id) return;

        const fruitTableData = getFruitTableData({ layer, check, fruits: check.avocados, config, user, all_defects });

        const preloadImages = async () => {
            let all_images = [
                ...(check.images ? check.images.map((x) => ({ ...x, label: check.label })) : []),
                ...(check.avocados ? check.avocados.flatMap((a) => a.images
                    .filter((x) => !device_image_types.includes(x.type)))
                    .filter((x) => x.url)
                    .map((x) => ({ ...x, label: check.label })) : [])
            ];
            all_images = await Promise.all(all_images.map(async (image) => ({
                ...image,
                url: await webpToJpgBase64(image.url)
            })));
            setPrintCheck({ ...check, label: layer.label, table_data: fruitTableData, all_images });
        };

        preloadImages();
    }, [layer, check, all_defects, params.test_id]);


    const [onSendReport] = useAvosApi("/notification/report-to-supplier", "POST", {}, {
        onSuccess: {
            message: "Report sending request received",
            showMessage: true
        }
    });
    const [pdfBlob, setPdfBlob] = useState<Blob>();
    const onRender = async ({ blob }) => {
        if (!pdfBlob || pdfBlob.size !== blob.size) {
            setPdfBlob(blob);
        }
    };

    if (!layer || !check || !printCheck || !location || !layer_config) {
        return <Loading />;
    }

    const intake_report_config = config.get_pdf_report(layer, "intake");
    const reportType = location.value;
    const report_config = config.get_pdf_report(layer, reportType) || {};

    const PrintReport = ({ onRender }) => {
        const logo_name = report_config.logo_name || intake_report_config.logo_name; // Use intake logo if not set
        const layer_fields = getFilledArrayOrDefault(location.summary_layer_fields_pdf).map((i) => i.set_value({
            config,
            layer,
            check: layer.latest_check, // * To be used in check tables and layer overview
            fruit: null, // * Fruit list is not available on overview screen
            children, // * children is only available for layer index your are viewing
            form_options // * used to translate form values to labels
        }));
        return (
            <>
                <PDFDocument onRender={onRender} title={`Check report ${layer?.label}`}>
                    <PDFPage footer logo={logo_name} header_title="Check report">
                        <View style={styles.row} wrap={false}>
                            <View style={styles.cell1}>
                                <PDFLayerMeta layer={layer} fields={layer_fields} />;
                            </View>
                        </View>
                    </PDFPage>

                    <PDFCheckFruitTables
                        title={`${printCheck.label}`}
                        header={printCheck.table_data[0]}
                        rows={printCheck.table_data[1]}
                        footer={printCheck.table_data[2]}
                        all_defects={all_defects}
                        defect_groups={location.defect_groups}
                        check={printCheck}
                    />

                    <PDFImages images={printCheck.all_images} />
                </PDFDocument>
            </>
        );
    };

    const isLoading: boolean = !printCheck || tenantsIsLoading;

    return <div>
        <div className="px-3 py-4 bg-gray">
            <Container>
                <div className="d-flex justify-content-end align-items-center">
                    <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                        <h3 className="mb-0 ">{layer_config.text} {layer.label} </h3>
                    </div>
                    <div className="pb-2 pb-sm-0 d-flex align-items-center ms-auto">
                        {report_config.show_send_email_button && onSendReport && <SendReportButton layerId={layer.id} blob={pdfBlob} reportType={reportType} onSendReport={onSendReport as any} />}
                        <Button className="btn-close my-1" size="lg" onClick={() => navigate(`/layer/${check.layer_id}/add-check/${check.test_id}/summary`)} ></Button>
                    </div>
                </div>
            </Container>
            <Container className="py-5">
                <div css={css`margin:auto;width: 420mm;`} >
                    {isLoading ? (
                        <LoadingProgressBar text={PDFCountDownText.slice(0, 4)} />
                    ) : (
                        <div>
                            {!pdfBlob && <LoadingProgressBar text={PDFCountDownText} startIndex={4} />}
                            <PDFViewer style={{ width: "100%", height: "1200px", opacity: pdfBlob ? 1 : 0 }}>
                                <PrintReport onRender={onRender} />
                            </PDFViewer>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    </div>;

}
