/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getStore } from "../../store";
import { convert_to_comma_seperated_list } from "../util";
import { AvosAction } from "../types";
import {
    BROWSE_HISTORY,
    CHECKS, CHILDREN, CREATE, DELETE, GET, GET_STAGE_COUNTER, LIST, LOCATION_HISTORY, PARENTS,
    PATCH,
    SUB_CREATE, UPDATE
} from "./actionsTypes";
import { LayerFilter, defaultLayer } from "./constants";
import { format2Backend } from "./util";

const endpoint = "layers/";

const getLayersMetaParams = (filter) => {
    const filterClone = { ...filter };
    const layersMeta = {};
    const metaKeys = [
        "country",
        "supplier",
        "article_name",
        "meta_purchase_order_number",
        "meta_container",
        "fruit_variety",
        "global_gap_number",
        "end_client",
        "next_action",
        "status_check",
        "flag",
        "fruit_type"
    ];
    metaKeys.forEach((key) => {
        layersMeta[key] = filterClone[key];
        delete filterClone[key];
    });
    return { layerParams: filterClone, layersMeta };
};

export const updateFilter = (filter: LayerFilter) => ({
    type: LIST.FILTER,
    payload: filter,
});

export function listLayers(filter: LayerFilter = {}) {
    const state = getStore()!.getState();
    const latestFilter = { ...state.layers.filter, ...filter };
    const { layerParams, layersMeta } = getLayersMetaParams(latestFilter);
    const params = {
        ...layerParams,
        layers_meta: JSON.stringify(layersMeta),
        select_fields: convert_to_comma_seperated_list(latestFilter.select_fields)
    };
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                filter_object: latestFilter,
                params,
                method: "GET",
                url: endpoint,
            },
        },
    } as unknown as AvosAction;
}

export function getLayer(layer_id: string) {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + layer_id}`,
            },
        },
    };
}

export function triggerLayerBusinessRules(layer_id: string) {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + layer_id}/business-rules`,
            },
        },
    };

}

export function createLayer(data) {
    data = format2Backend(data);
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data
            },
        },
    } as unknown as AvosAction;
}

export function createSubLayer(data) {
    data = format2Backend(data);
    return {
        types: [SUB_CREATE.LOADING, SUB_CREATE.SUCCESS, SUB_CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data
            },
        },
    };
}


export function updateLayerState(data) {
    data = format2Backend(data);
    return {
        type: GET.SUCCESS,
        payload: { data },
    };
}
export function resetLayerState() {
    const data = { ...defaultLayer, meta: [] }; // * dummy empty layer from backend
    return {
        type: GET.SUCCESS,
        payload: { data },
    };
}
export function updateLayer(data) {
    data = format2Backend(data);
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint + data.id}`,
                data,
            },
        },
    };
}

export function patchLayer(layer_id, field, value, callApi = true) {
    // only update the field in the store
    if (!callApi) {
        return {
            type: PATCH.STATE,
            payload: { field, value },
        };
    }

    const data = {
        field,
        state_value: value,
        value: convert_to_comma_seperated_list(value)
    };

    // run through the axios-redux middleware loop
    return {
        types: [PATCH.LOADING, PATCH.SUCCESS, PATCH.ERROR],
        payload: {
            request: {
                method: "PATCH",
                url: `${endpoint + layer_id}`,
                data,
            },
        },
    };
}
export function deleteLayer(id) {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                layer_id: id,
                url: `${endpoint + id}/`,
            },
        },
    };
}

export function getLayerParents(layer_id) {
    return {
        types: [PARENTS.LOADING, PARENTS.SUCCESS, PARENTS.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `${endpoint + layer_id}/parents`,
            },
        },
    };
}

export function getLayerChildren(layer_id) {
    return {
        types: [CHILDREN.LOADING, CHILDREN.SUCCESS, CHILDREN.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `${endpoint + layer_id}/children`,
            },
        },
    };
}

export function getLayerChecks(layer_id, params) {
    return {
        types: [CHECKS.LOADING, CHECKS.SUCCESS, CHECKS.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `${endpoint + layer_id}/checks`,
                params
            },
        },
    };
}

// current display layer is parent_layer_id
export function addLayerChild(parent_layer_id: string, layer_id: string) {
    return {
        types: [CHILDREN.LOADING, CHILDREN.SUCCESS, CHILDREN.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + parent_layer_id}/children`,
                data: {
                    parent_layer_id, layer_id
                }
            },
        },
    };
}

export function createAndAddLayerChild(parent_layer_id: string, layer_label: string, layer_type: string) {
    return {
        types: [CHILDREN.LOADING, CHILDREN.SUCCESS, CHILDREN.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + parent_layer_id}/children?create_child=${true}`,
                data: {
                    parent_layer_id, layer_label, layer_type
                }
            },
        },
    };
}

export function removeLayerChild(parent_layer_id, layer_id) {
    return {
        types: [CHILDREN.LOADING, CHILDREN.SUCCESS, CHILDREN.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint + parent_layer_id}/children/${layer_id}`,
            },
        },
    };
}

// current display layer is layer_id
export function addLayerParent(layer_id, parent_layer_id) {
    return {
        types: [PARENTS.LOADING, PARENTS.SUCCESS, PARENTS.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + layer_id}/parents`,
                data: {
                    parent_layer_id, layer_id
                }
            },
        },
    };
}

export function removeLayerParent(layer_id, parent_layer_id) {
    return {
        types: [PARENTS.LOADING, PARENTS.SUCCESS, PARENTS.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint + layer_id}/parents/${parent_layer_id}`,
            },
        },
    };
}

export function getLayerLocationHistory(layer_id) {
    return {
        types: [LOCATION_HISTORY.LOADING, LOCATION_HISTORY.SUCCESS, LOCATION_HISTORY.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `${endpoint + layer_id}/locations`,
            },
        },
    };
}

export function addLayerLocationHistory(data) {
    return {
        types: [LOCATION_HISTORY.LOADING, LOCATION_HISTORY.SUCCESS, LOCATION_HISTORY.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + data.layer_id}/locations`,
                data
            },
        },
    };
}

export function deleteLayerLocationHistory(data) {
    return {
        types: [LOCATION_HISTORY.LOADING, LOCATION_HISTORY.SUCCESS, LOCATION_HISTORY.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint + data.layer_id}/locations/${data.id}`,
                data
            },
        },
    };
}

export function addLayerImage(data) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `/images/layer/${data.layer_id}/add_image/`,
                data,
            },
        },
    };
}

export function deleteLayerImage(data) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: `/images/layer/${data.layer_id}/delete_image/`,
                data,
            },
        },
    };
}


export function countLayerInStage() {
    return {
        types: [GET_STAGE_COUNTER.LOADING, GET_STAGE_COUNTER.SUCCESS, GET_STAGE_COUNTER.ERROR],
        payload: {
            request: {
                method: "GET",
                no_toast: true,
                url: `/layers/count-layer-in-stage/`,
            },
        },
    } as unknown as AvosAction;

}

export function addLayerBrowseHistory(layer) {
    return {
        type: BROWSE_HISTORY.SUCCESS,
        payload: {
            id: layer.id,
            type: layer.type,
            label: layer.label,
            text: layer.text
        }
    };
}
