/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { updateInternalEmailsFilter, getInternalEmails, saveInternalEmails, deleteInternalEmails } from "../../../actions/Admin/internalEmails/actions";
import { RootState } from "../../../reducers";
import { FormField, FormInlineFormField, FormRadioField, FormTextListField } from "../../../actions/Tenants/config/constantsTyped";
import { validateEmail } from "../../../utils";
import { PageBase, Form, PageBaseTableRowField, FormOptions } from "./PageBase";
import { Filter } from "../../../actions/Admin/reducer";
import { InternalEmailsState } from "../../../actions/Admin/internalEmails/reducer";
import { SupplierInfo } from "../../../actions/Admin/supplier/reducer";
import { MailRecipients } from "../Helpers";
import { convertEmailRecipientsObjectIntoTextList, convertEmailRecipientTextListIntoObject, EmailRecipientsTextList } from "./utils";
import useConfig from "../../../actions/Tenants/config/configHook";

const emailRecipientField: FormField = {
    label: "",
    name: "address",
    type: "email",
    validate: validateEmail
};

const createEmailRecipientsField = (name: string, disabled?: boolean): FormTextListField => ({
    label: name,
    name: name.toLowerCase(),
    type: "text-list",
    field: emailRecipientField,
    disabled
});

export interface SupplierInfoFormObject {
    fruit_type?: string;
    info_level?: string;
    email_recipients: EmailRecipientsTextList;
}

const defineInternalEmailsFormCreate = (onSaveInternalEmails: (info: SupplierInfo) => void, onDeleteInternalEmails:(id:string)=>void) => (formOptions: FormOptions | undefined, currentEntity: SupplierInfo, modalObject: SupplierInfoFormObject, state: InternalEmailsState) => {
    const fruitOptions = formOptions?.fruit_type || [];
    const fruitTypeField: FormRadioField = {
        label: "Fruit(s)",
        name: "fruit_type",
        type: "radio",
        mandatory: true,
        options: fruitOptions,
        default_value: fruitOptions?.[0]?.value,
    };
    const infoLevelField: FormRadioField = {
        label: "Information level",
        name: "info_level",
        type: "radio",
        options: [
            { value: "supplier", label: "All fruits" },
            { value: "fruit", label: "Select fruit" },
        ],
        default_value: "supplier",
    };
    const emailsField: FormInlineFormField = {
        label: "Email",
        name: "email_recipients",
        type: "inline-form",
        fields: [
            createEmailRecipientsField("Cc"),
        ],
    };
    const [internalEmailsForm, setSupplierInfoForm] = useState<FormField[]>([
        infoLevelField,
    ]);
    function updateSupplierInfoForm(infoLevel: string): void {
        const updatedForm: FormField[] = [
            internalEmailsForm[0],
        ];

        if (infoLevel === "fruit") {
            updatedForm.push(fruitTypeField);
        }

        updatedForm.push(emailsField);

        setSupplierInfoForm(updatedForm);
    }
    const getFormObjectFromEntity = (entity: Partial<SupplierInfo>, fruitType?: string, infoLevel?: string): Partial<SupplierInfoFormObject> => {
        return {
            ...entity,
            fruit_type: fruitType || entity.fruit_type || undefined,
            info_level: infoLevel || undefined,
            email_recipients: convertEmailRecipientsObjectIntoTextList(entity.email_recipients),
        };
    };
    const onFieldValueChange = (field: string, value: any, formObject: Partial<SupplierInfoFormObject>, entity: Partial<SupplierInfo>) => {
        if (field === "info_level") {
            updateSupplierInfoForm(value);
            return getFormObjectFromEntity(entity, undefined, value);
        }
        if (field === "fruit_type") {
            return getFormObjectFromEntity(entity, value, formObject?.info_level);
        }
        return {
            ...formObject,
            [field]: value,
        } as SupplierInfoFormObject;
    };
    const onPreEditModalOpen = () => {
        setSupplierInfoForm([emailsField]);
    };
    const onPreAddModalOpen = () => {
        setSupplierInfoForm([
            infoLevelField,
            fruitTypeField,
            emailsField,
        ]);
    };
    const onSave = (formObject: SupplierInfoFormObject, entity: Partial<SupplierInfo>) => {
        const internalEmails = {
            ...entity,
            fruit_type: formObject.fruit_type,
            email_recipients: convertEmailRecipientTextListIntoObject(formObject.email_recipients),
        };
        // check if there is already an entity with the same fruit type
        const existingEntity = state.items.results.find((item) => item.fruit_type === internalEmails.fruit_type && item.id !== internalEmails.id);
        if (existingEntity) {
            toast.error("Internal emails for this fruit type already exist");
        } else {
            onSaveInternalEmails(internalEmails as SupplierInfo);
        }
    };
    const onDelete = (formObject: SupplierInfoFormObject, entity: Partial<SupplierInfo>) => {
        onDeleteInternalEmails(entity.id!);
    };
    const editModal: Form<SupplierInfoFormObject, SupplierInfo> = {
        title: "Internal emails",
        fields: internalEmailsForm,
        modalButtonLabel: "Edit",
        onSave,
        onDelete,
        onFieldValueChange,
        getFormObjectFromEntity,
        onPreEditModalOpen,
        onPreAddModalOpen
    };
    return editModal;
};

const InternalEmailsPage = () => {
    const dispatch = useDispatch();
    const state = useSelector<RootState, InternalEmailsState>((state) => state.admin.internalEmails);
    const config = useConfig();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const getOptionLabel = (optionType: string, value: any): string => {
        const options = allOptions?.[config.tenant_id]?.[optionType] || [];
        const option = options.find((option) => option.value === value);
        return option?.label || "";
    };
    const onSaveInternalEmails = async (entity: SupplierInfo) => {
        await dispatch(saveInternalEmails(entity));
    };
    const onDelete = async (id: string) => {
        await dispatch(deleteInternalEmails(id));
    };
    const onUpdateFilter = (filter: Filter) => {
        dispatch(updateInternalEmailsFilter(filter));
    };
    const onListEntities = (filter: Filter) => {
        dispatch(getInternalEmails(filter));
    };
    const tableRow: PageBaseTableRowField<SupplierInfo>[] = [
        {
            label: "Fruit",
            name: "fruit_type",
            display_component(entity: SupplierInfo) {
                return getOptionLabel("fruit_type", entity.fruit_type) || "All";
            },
            lg: 2,
        },
        {
            label: "Email Recipients",
            name: "email_recipients",
            display_component(entity: SupplierInfo) {
                return <MailRecipients recipients={entity.email_recipients || {}} />;
            },
            lg: 5,
        },
    ];
    const createEmailsForm = defineInternalEmailsFormCreate(onSaveInternalEmails, onDelete);
    const createForms = [createEmailsForm];
    return <PageBase<SupplierInfo>
        title="Internal Email"
        tableRow={tableRow}
        createForms={createForms as any}
        state={state}
        events={{ onUpdateFilter, onListEntities }}
    />;
};


export default InternalEmailsPage;


