/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { deleteSupplier, getSuppliers, saveSupplier, updateSupplierFilter } from "../../../actions/Admin/supplier/actions";
import { RootState } from "../../../reducers";
import { FormField, FormSingleSelectField, FormTextListField } from "../../../actions/Tenants/config/constantsTyped";
import { validateEmail } from "../../../utils";
import { PageBase, Form, PageBaseTableRowField, FormOptions } from "./PageBase";
import { EmailRecipients, Filter } from "../../../actions/Admin/reducer";
import { Supplier, SupplierInfo, SupplierState } from "../../../actions/Admin/supplier/reducer";
import { MailRecipients } from "../Helpers";
import { convertEmailRecipientsObjectIntoTextList, convertEmailRecipientTextListIntoObject, EmailRecipientsTextList } from "./utils";
import useConfig from "../../../actions/Tenants/config/configHook";
import { getAllTenantFieldOptions } from "../../../actions/Tenants/actions";

const emailRecipientField: FormField = {
    label: "",
    name: "address",
    type: "email",
    validate: validateEmail
};

const createEmailRecipientsField = (name: string, disabled?: boolean): FormTextListField => ({
    label: "Email to:",
    name: name.toLowerCase(),
    type: "text-list",
    field: emailRecipientField,
    disabled
});

const generateName = (label: string) => label.toLowerCase().replace(/ /g, "-");

interface SupplierFormObject extends Omit<Supplier, "name" | "infos" | "fruit_types"> {
    fruit_type: string[];
}

const defineSupplierFormCreate = (onSaveSupplier: (supplier: Supplier) => void, onDeleteSupplier: (id: string) => void) => (formOptions: FormOptions | undefined) => {
    const fruitOptions = formOptions?.fruit_type ?? [];
    const supplierForm: FormField[] = [
        {
            label: "Supplier Name",
            name: "label",
            type: "text",
            mandatory: true,
            pattern: "^(?!\\s*$).+$",
            invalid_hint: "String consists of spaces is not allowed",
        },
        {
            label: "Country",
            name: "country",
            type: "select-managed",
        },
        {
            label: "Fruit(s)",
            name: "fruit_type",
            type: "single-select",
            mandatory: true,
            multi: true,
            return_array: true,
            options: fruitOptions
        },
    ];
    const onSave = (formObject: SupplierFormObject, entity: Partial<Supplier>) => {
        const supplier: any = {
            ...entity,
            ...formObject,
            fruit_types: formObject.fruit_type,
            fruit_type: undefined,
            name: entity.name || generateName(formObject.label),
            infos: { ...entity.infos } // if infos is undefined this will assign an empty object
        };
        if (!supplier.infos) supplier.infos = {};
        onSaveSupplier(supplier as Supplier);
    };
    const onDelete = (_: SupplierFormObject, entity: Partial<Supplier>) => {
        onDeleteSupplier(entity.id!);
    };
    const getFormObjectFromEntity = (entity: Partial<Supplier>): Partial<SupplierFormObject> => {
        return {
            ...entity,
            fruit_type: entity.fruit_types || [],
        };
    };
    const supplierModal: Form<SupplierFormObject, Supplier> = {
        title: "Supplier",
        fields: supplierForm,
        modalButtonLabel: "Edit",
        onSave,
        onDelete,
        getFormObjectFromEntity
    };
    return supplierModal;
};

export interface SupplierInfoFormObject {
    fruit_type?: string;
    info_level?: string;
    email_recipients: EmailRecipientsTextList;
}

const defineSupplierInfoFormCreate = (onSaveSupplier: (supplier: Supplier) => void) => (formOptions: FormOptions | undefined, supplier: Supplier | undefined) => {
    const fruitOptions = (supplier?.fruit_types ?? []).map(
        (fruit_type) => ({
            value: fruit_type,
            label: (formOptions?.fruit_type ?? []).find((option) => option.value === fruit_type)?.label || fruit_type
        })
    );
    const fruit_type_form: FormField = {
        label: "Fruit type",
        name: "fruit_type",
        type: "radio",
        options: fruitOptions,
        default_value: fruitOptions?.[0]?.value,
        mandatory: true,
    };
    const [supplierInfoForm, setSupplierInfoForm] = useState<FormField[]>([
        {
            label: "Information level",
            name: "info_level",
            type: "radio",
            options: [
                { value: "supplier", label: "Supplier" },
                { value: "fruit", label: "Fruit" },
            ],
            default_value: "supplier",
        },
    ]);
    function updateSupplierInfoForm(infoLevel: string): void {
        const updatedForm: FormField[] = [
            supplierInfoForm[0],
        ];

        if (infoLevel === "fruit") {
            updatedForm.push(fruit_type_form);
        }

        updatedForm.push({
            label: "",
            name: "email_recipients",
            type: "inline-form",
            fields: [
                createEmailRecipientsField("To"),
            ],
        });

        setSupplierInfoForm(updatedForm);
    }
    const getFormObjectFromEntity = (entity: Partial<Supplier>, fruitType?: string, infoLevel?: string): Partial<SupplierInfoFormObject> => {
        fruitType = fruitType || "*";
        const info: Partial<SupplierInfo> = entity.infos?.[fruitType] || {};
        const { email_recipients } = info;
        return {
            ...info,
            fruit_type: fruitType === "*" ? undefined : fruitType,
            info_level: infoLevel || undefined,
            email_recipients: convertEmailRecipientsObjectIntoTextList(email_recipients),
        };
    };
    const onFieldValueChange = (field: string, value: any, formObject: Partial<SupplierInfoFormObject>, entity: Partial<Supplier>) => {
        if (field === "info_level") {
            updateSupplierInfoForm(value);
            return getFormObjectFromEntity(entity, undefined, value);
        }
        if (field === "fruit_type") {
            return getFormObjectFromEntity(entity, value, formObject?.info_level);
        }
        return {
            ...formObject,
            [field]: value,
        } as SupplierInfoFormObject;
    };
    const onSave = (formObject: SupplierInfoFormObject, entity: Partial<Supplier>) => {
        const fruit_type = formObject.fruit_type || "*";
        const supplier = {
            ...entity,
            infos: {
                ...entity.infos,
                [fruit_type]: {
                    ...entity.infos?.[fruit_type],
                    fruit_type,
                    email_recipients: convertEmailRecipientTextListIntoObject(formObject.email_recipients),
                },
            },
        };
        onSaveSupplier(supplier as Supplier);
    };
    const onDelete = (formObject: SupplierInfoFormObject, entity: Partial<Supplier>) => {
        const supplier = {
            ...entity,
            infos: {
                ...entity.infos,
                [formObject.fruit_type || "*"]: undefined,
            },
        };
        onSaveSupplier(supplier as Supplier);
    };
    const supplierInfoModal: Form<SupplierInfoFormObject, Supplier> = {
        title: "Supplier Info",
        fields: supplierInfoForm,
        modalButtonLabel: "Add Email(s)",
        onSave,
        onDelete,
        onFieldValueChange,
        getFormObjectFromEntity
    };
    return supplierInfoModal;
};

interface GrowerRelationFormObject {
    fruit_type: string;
    grower: string[];
}

const defineGrowerRelationFormCreate = (onSaveSupplier: (supplier: Supplier) => void) => (formOptions: FormOptions | undefined, supplier: Supplier | undefined) => {
    const fruitOptions = (supplier?.fruit_types ?? []).map(
        (fruit_type) => ({
            value: fruit_type,
            label: (formOptions?.fruit_type ?? []).find((option) => option.value === fruit_type)?.label ?? fruit_type
        })
    );
    const growerOptions = formOptions?.grower ?? [];
    const [growersField, setGrowersField] = useState<FormSingleSelectField>({
        label: "Grower(s)",
        name: "grower",
        type: "single-select",
        mandatory: true,
        multi: true,
        return_array: true,
        options: growerOptions
    });
    useEffect(() => {
        const fruit_type = fruitOptions?.[0]?.value;
        setGrowersField({
            ...growersField,
            options: growerOptions.filter((grower) => (grower as any).fruit_type === fruit_type && supplier?.country === (grower as any).country)
        });
    }, [supplier]);
    const growerRelationForm: FormField[] = [
        {
            label: "Fruit type",
            name: "fruit_type",
            type: "radio",
            mandatory: true,
            options: fruitOptions,
            default_value: fruitOptions?.[0]?.value,
        },
        growersField,
    ];
    const getFormObjectFromEntity = (entity: Partial<Supplier>, fruitType?: string): Partial<GrowerRelationFormObject> => {
        const selectedFruitType = fruitType || Object.keys(entity.growers || {})[0];
        if (selectedFruitType) {
            const relations = entity.growers?.[selectedFruitType] ?? [];
            return {
                fruit_type: selectedFruitType,
                grower: relations.map((relation) => relation.grower_name),
            };
        }
        return {};
    };

    const onFieldValueChange = (field: string, value: any, formObject: Partial<GrowerRelationFormObject>, entity: Partial<Supplier>) => {
        if (field === "fruit_type") {
            setGrowersField({
                ...growersField,
                options: growerOptions.filter((grower) => (grower as any).fruit_type === value && supplier?.country === (grower as any).country)
            });
            return {
                ...getFormObjectFromEntity(entity, value),
                [field]: value,
            };
        }
        return {
            ...formObject,
            [field]: value,
        } as GrowerRelationFormObject;
    };
    const onSave = (formObject: GrowerRelationFormObject, entity: Partial<Supplier>) => {
        const { fruit_type, grower } = formObject;
        const supplier = {
            ...entity,
            growers: {
                ...entity.growers,
                [fruit_type]: grower.map((grower_name) => ({
                    grower_name,
                    fruit_type,
                }))
            },
        };
        onSaveSupplier(supplier as Supplier);
    };
    const onDelete = (formObject: GrowerRelationFormObject, entity: Partial<Supplier>) => {
        const supplier = {
            ...entity,
            growers: {
                ...entity.growers,
                [formObject.fruit_type]: undefined,
            },
        };
        onSaveSupplier(supplier as Supplier);
    };
    const growerRelationModal: Form<GrowerRelationFormObject, Supplier> = {
        title: "Supplier Grower Relation",
        fields: growerRelationForm,
        modalButtonLabel: "Link Grower(s)",
        onSave,
        onDelete,
        onFieldValueChange,
        getFormObjectFromEntity
    };
    return growerRelationModal;
};

const SupplierPage = () => {
    const dispatch = useDispatch();
    const state = useSelector<RootState, SupplierState>((state) => state.admin.supplier);
    const config = useConfig();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const getOptionLabel = (optionType: string, value: any): string => {
        const options = allOptions?.[config.tenant_id]?.[optionType] || [];
        const option = options.find((option) => option.value === value);
        return option?.label || "";
    };
    const onSaveSupplier = async (entity: Supplier) => {
        await dispatch(saveSupplier(entity));
        dispatch(getAllTenantFieldOptions(config.tenant_id) as any);
    };
    const onDeleteSupplier = async (id: string) => {
        await dispatch(deleteSupplier(id));
        dispatch(getAllTenantFieldOptions(config.tenant_id) as any);
    };
    const onUpdateFilter = (filter: Filter) => {
        dispatch(updateSupplierFilter(filter));
    };
    const onListEntities = (filter: Filter) => {
        dispatch(getSuppliers(filter));
    };
    const tableRow: PageBaseTableRowField<Supplier>[] = [
        {
            label: "Supplier Name",
            name: "label",
            lg: 2,
        },
        {
            label: "Country",
            name: "country",
            display_component(entity: Supplier) {
                return getOptionLabel("country", entity.country);
            },
            lg: 1,
        },
        {
            label: "Fruit(s)",
            name: "fruit_types",
            display_component(entity: Supplier) {
                return entity.fruit_types.map((fruit_type) => (getOptionLabel("fruit_type", fruit_type)) || []).join(", ");
            },
            lg: 2,
        },
        {
            label: "Email Recipients",
            name: "email_recipients",
            display_component(entity: Supplier) {
                const email_recipients = Object.entries(entity.infos).reduce((acc, [, info]) => {
                    return {
                        to: [...acc.to, ...info.email_recipients?.to || []],
                        cc: [...acc.cc, ...info.email_recipients?.cc || []],
                        bcc: [...acc.bcc, ...info.email_recipients?.bcc || []],
                    };
                }, { to: [], cc: [], bcc: [] } as EmailRecipients);
                return <MailRecipients recipients={email_recipients} />;
            },
            lg: 4,
        },
    ];
    const createSupplierForm = defineSupplierFormCreate(onSaveSupplier, onDeleteSupplier);
    const createSupplierInfoForm = defineSupplierInfoFormCreate(onSaveSupplier);
    const createGrowerRelationForm = defineGrowerRelationFormCreate(onSaveSupplier);
    const createForms = [createSupplierInfoForm, createGrowerRelationForm, createSupplierForm];
    const defaultModalIndex = createForms.indexOf(createSupplierForm);
    return <PageBase<Supplier>
        title="Supplier"
        tableRow={tableRow}
        createForms={createForms as any}
        state={state}
        events={{ onUpdateFilter, onListEntities }}
        defaultModalIndex={defaultModalIndex}
    />;
};


export default SupplierPage;


