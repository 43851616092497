/* eslint-disable @typescript-eslint/no-non-null-assertion */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { useSelector } from "react-redux";
import { PaginationRow } from "../../Helper/PaginationRow";
import { Loading } from "../../Helper/Loading";
import { FormField, SelectOption } from "../../../actions/Tenants/config/constantsTyped";
import MetaForm, { metaFormIsValid } from "../../Forms/MetaForm";
import useConfig from "../../../actions/Tenants/config/configHook";
import { filterResults } from "./utils";
import { Entity, EntityState, Filter } from "../../../actions/Admin/reducer";
import { ConfirmationModal } from "../Helper/ConfirmationModal";
import { ModalResult } from "../Helper/Modal";

const tableStyles = css`
    .table-hover tbody tr:hover {
        background-color: #f5f5f5; /* Customize the hover color here */
        color: #333; /* Customize the text color on hover */
        cursor: pointer; /* Change the cursor to pointer on hover */
    }
    td, th {
        vertical-align: bottom; /* Center content vertically */
    }
`;

const modalFooterStyles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const rightAlignButtons = css`
    display: flex;
    gap: 10px; /* Optional: Add spacing between the buttons */
    margin-left: auto; /* Push the group to the right */
`;

export interface PageBaseTableRowField<T> {
    label: string;
    name: string;
    display_component?: (entity: T) => JSX.Element | string;
    lg?: number;
}

export interface Form<FormObject extends object = object, RowEntity extends Entity = Entity> {
    title: string;
    fields: FormField[];
    modalButtonLabel: string;
    onSave: (newObject: FormObject, entity: Partial<RowEntity>) => void;
    onDelete: (newObject: FormObject, entity: Partial<RowEntity>) => void;
    /**
     * This function is called when a field in a modal form is changed.
     * @param field The name of the field that was changed.
     * @param value The new value of the field.
     * @param entity The current entity of the selected row.
     * @returns The new object of the modal form.
    *
    */
    onFieldValueChange?: (field: string, value: any, formObject: Partial<FormObject>, entity: Partial<RowEntity>) => Partial<FormObject>;
    getFormObjectFromEntity: (entity: Partial<RowEntity>) => Partial<FormObject>;
    onPreEditModalOpen?: () => void;
    onPreAddModalOpen?: () => void;
}

export interface FormOptions { [optionType: string]: SelectOption[] }

export interface PageBaseProps<T extends Entity> {
    title: string;
    tableRow: PageBaseTableRowField<T>[];
    state: EntityState<T>;
    events: {
        onUpdateFilter: (filter: Filter) => void;
        onListEntities: (filter: Filter) => void;
    },
    createForms: Array<(formOptions: FormOptions | undefined, entity: Partial<T> | undefined, formObject: object, state: EntityState<T>) => Form>;
    defaultModalIndex?: number;
}

export const PageBase = <T extends Entity>({
    title,
    tableRow,
    state,
    events: { onUpdateFilter, onListEntities },
    createForms,
    defaultModalIndex = 0,
}: PageBaseProps<T>) => {
    const config = useConfig();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [currentEntity, setCurrentEntity] = useState<Partial<T>>({});
    const [modalObject, setModalObject] = useState({} as any);
    const [selectedModalIndex, setSelectedModalIndex] = useState<number>(-1);
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const tenant_options = allOptions?.[config.tenant_id];
    const forms = createForms.map((form) => form(tenant_options, currentEntity, modalObject, state));
    const selectedModal = forms[selectedModalIndex];
    const isModalOpen = selectedModalIndex > -1 && !!selectedModal;
    const setModalClosed = () => setSelectedModalIndex(-1);
    const { items, isLoading, filter, localFiltering, reload } = state;
    const results = localFiltering ? filterResults(items.results, filter) : items.results;

    useEffect(() => {
        if ((!isLoading && (!localFiltering || !results?.length)) || reload) {
            onListEntities(localFiltering ? {} : filter);
        }
    }, [filter, localFiltering, reload]);

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            onUpdateFilter({ ...filter, offset });
        }
    };
    const updateLimit = (limit) => {
        onUpdateFilter({ ...filter, limit });
    };
    const onDeleteClick = () => {
        setConfirmationModalOpen(true);
    };
    const onConfirm = () => {
        selectedModal.onDelete(modalObject, currentEntity);
        setModalClosed();
    };

    const onCancelClick = () => {
        setModalClosed();
    };
    const onAddRowClick = async () => {
        const selectedModal = forms[defaultModalIndex];
        await selectedModal.onPreAddModalOpen?.();
        setCurrentEntity({});
        setSelectedModalIndex(defaultModalIndex);
        setModalObject({});
    };
    const onSaveClick = () => {
        if (metaFormIsValid(selectedModal.fields, modalObject)) {
            selectedModal.onSave(modalObject, currentEntity);
            setModalClosed();
        }
    };
    const onModalButtonClick = async (modalIndex: number, entity: T) => {
        const selectedModal = forms[modalIndex];
        selectedModal.onPreEditModalOpen?.();
        setCurrentEntity(entity);
        setSelectedModalIndex(modalIndex);
        setModalObject(selectedModal.getFormObjectFromEntity(entity));
    };
    const onConfirmClose = (result: ModalResult) => {
        if (result === ModalResult.Save) {
            onConfirm();
        }
        setConfirmationModalOpen(false);
    };
    const tableThStyle = css`color: black !important;background: #e5e8e5!important; border-right: 1px solid white;`;
    return <>
        <ConfirmationModal size="md" isOpen={confirmationModalOpen} onClose={onConfirmClose}>Are you sure you want to delete this {selectedModal?.title} ?</ConfirmationModal>
        <Modal isOpen={isModalOpen} size="l" onClosed={onCancelClick}>
            <ModalHeader><span>{selectedModal?.title || title}</span></ModalHeader>
            <ModalBody>
                <MetaForm
                    meta={selectedModal?.fields || []}
                    setValue={(field: string, value: unknown) => {
                        if (selectedModal.onFieldValueChange) {
                            setModalObject(selectedModal.onFieldValueChange(field, value, modalObject, currentEntity));
                            return;
                        }
                        setModalObject({ ...modalObject, [field]: value, });
                    }}
                    object={modalObject}
                    config={config}
                />
            </ModalBody>
            <ModalFooter css={modalFooterStyles}>
                {modalObject.id && <Button color="danger" outline onClick={onDeleteClick}>Delete</Button>}
                <div css={rightAlignButtons}>
                    <Button color="secondary" onClick={onCancelClick}>Cancel</Button>
                    <Button color="primary" onClick={onSaveClick}>Save</Button>
                </div>
            </ModalFooter>
        </Modal>
        <Container className="d-flex flex-column" css={css`min-height: 50vh;`} >
            <>
                {isLoading && <Loading />}
                {!isLoading
                    && <>
                        <Row>
                            <Col md="12" className="d-flex justify-content-end">
                                <Button className="m-2" outline color="primary" onClick={onAddRowClick}>Add {title}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table size="sm" responsive={true} borderless={true} className="table-hover" css={tableStyles}>
                                    <thead>
                                        <tr className="">
                                            {tableRow.map((field) => <th key={field.name} className={`${"lg" in field ? `col-${field.lg}` : ""}`} css={tableThStyle} >{field.label}</th>)}
                                            <th className="col-3" css={tableThStyle} />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {results.map((entity) => {
                                            return (
                                                <tr key={entity.id}>
                                                    {tableRow.map((field) => {
                                                        if (field.display_component) {
                                                            return <td key={field.name}>{field.display_component(entity)}</td>;
                                                        }
                                                        return <td key={field.name}>{entity[field.name]}</td>;
                                                    })}
                                                    <td className="d-flex justify-content-end">
                                                        {
                                                            forms.map((modal, index) => <Button key={index} size="sm" className="m-1" color="light" onClick={() => onModalButtonClick(index, entity)}>{modal.modalButtonLabel}</Button>)
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }
            </>
            <PaginationRow
                results={results}
                totalCount={items.count}
                filter={filter}
                onPageChanged={onPageChanged}
                updateLimit={updateLimit}
            />
        </Container>
    </>;
};

