import { useRoutes } from "react-router-dom";
import NoMatch404 from "./PageNotFound";
import LandingPage from "./Landing";
import Supplier from "./Supplier";
import Grower from "./Grower";
import InternalEmails from "./InternalEmails";

export type AdminPageRouteObject = {
    title: string;
    path: string;
    element?: JSX.Element;
    children?: AdminPageRouteObject[];
}

export const adminMenu: AdminPageRouteObject[] = [
    {
        title: "Sourcing",
        path: "sourcing",
        children: [
            {
                title: "Supplier",
                path: "supplier",
                element: <Supplier />
            },
            {
                title: "Grower",
                path: "grower",
                element: <Grower />
            },
            {
                title: "Internal Emails",
                path: "internal-emails",
                element: <InternalEmails />
            },
        ],
    },
    // hide this for now
    // {
    //     title: "Fruit",
    //     path: "fruit",
    //     children: [
    //         {
    //             title: "Variety",
    //             path: "variety",
    //             element: <div>Variety</div>
    //         },
    //         {
    //             title: "Size",
    //             path: "size",
    //             element: <div>Size</div>
    //         },
    //     ]
    // },
    // {
    //     title: "Delivery",
    //     path: "delivery",
    //     children: [
    //         {
    //             title: "End Clients",
    //             path: "end-clients",
    //         },
    //         {
    //             title: "Sup-refs",
    //             path: "sup-refs",
    //         },
    //         {
    //             title: "Products",
    //             path: "products",
    //         },
    //     ]
    // },
];

export function AdminPageRouter() {
    const router = useRoutes([
        {
            title: "Landing",
            path: "",
            element: <LandingPage />
        },
        ...adminMenu,
        {
            title: "Page Not Found",
            path: "*",
            element: <NoMatch404 />
        }
    ]);
    return router;
}
