import { Html5QrcodeSupportedFormats } from "html5-qrcode";
import Field from "../../../components/scenes/Layers/fields/Field";
import { CHECK_CARD_TYPE, Check, LAYER_STAGE, LAYER_TYPE, Layer, VIEW } from "../../Layers/constants";
import { ColorConfig, FruitConfig } from "./fruitTypes";


export type ConfigItemType = {
    id: string,
    type: string,
    value: string,
    config: object
}


export interface CheckSummary {
    meta?: Field[];
    pressure_min_max?: {
        field: string;
        unity?: string;
        speed?: boolean; // show ripening speed compared to earlier check
        categories?: RipeningStage[];
    } | false;
    categories?: {
        field: string;
        title?: string | boolean;
        categories?: RipeningStage[];
    }[]; // array of categories to show in the summary, can be used to generate pie charts
}

export interface FormFieldOnChangeProps {
    setValue: (field: string, value: unknown) => void;
    field: string;
    value: any;
    object: any;
}
export interface IFormField<T = string> {
    label: string,
    name: string,
    type: T,
    mandatory?: boolean,
    onchange_callback?: string | (({ setValue, field, value, object }: FormFieldOnChangeProps) => void),
    validate?: (value) => boolean,
    get_value?: (value) => any,
    invalid_hint?: string,
    disabled?: boolean
    default_value?: any;
    pattern?: string
    children_meta?: boolean;
    defect_position?: string[];
    defect_severity?: string[];
    deprecated?: boolean; // set deprecated to true to hide the field as predifined form
}

export interface RadioOption {
    value: string;
    text: string;
}
export interface RadioOptionWithLabel {
    value: boolean | string | number;
    label: string;
}

export interface OptionFlagged {
    value: string | number | boolean;
    text: JSX.Element | string;
    color?: string;
    className?: string;
    disabled?: boolean;
}

export interface SelectOption {
    value: string;
    label: string;
}

export interface ImageOption extends OptionFlagged {
    text: string;
}

export interface FormDateRadioButtonField extends IFormField<"date-radiobutton"> {
    backward: number;
    forward: number;
}
export interface FormSelectManagedField extends IFormField<"select-managed"> {
    multi?: boolean;
    options?: SelectOption[];
    lg?: number;
    hide_when_no_options?: boolean;
    return_array?: boolean;
}
export interface FormRadioField extends IFormField<"radio"> {
    options: RadioOption[] | OptionFlagged[] | RadioOptionWithLabel[];
    lg?: number;
    // color?: ColorConfig[];
}
export interface FormImageRadioButtonsField extends IFormField<"image-radiobutton"> {
    options: ImageOption[];
    get_image_position: (value: string, object: any) => [number, number];
    image: {
        path: string;
        nativeSize: [number, number],
        optionDisplaySize: [number, number],
        scale: number
    }
}

export interface FormNumberField extends IFormField<"number"> {
    min?: number;
    max?: number;
    unity?: string;
    invalid?: boolean;
    lg?: number;
}

export interface FormTextField extends IFormField<"text"> {
    lg?: number;
}
export interface FormRadioManagedField extends IFormField<"radio-managed"> {
    options_name?: string;
}
export interface FormCheckboxManagedField extends IFormField<"checkbox-managed"> {
    options_name?: string;
}
export type FormQRCodeScannerField = IFormField<"text-qrcode-scanner">;
export interface FormLayerSelectMulti extends IFormField<"layer-select-multi"> {
    meta_options?: string[];
}

export interface FormLayerSelect extends IFormField<"layer-select"> {
    /**
     * If set, the layer select will only show layers of this type
     */
    layer_type?: string;

    /**
     * If set to true, the layer select will allow multiple layers to be selected
     */
    isMulti?: boolean;
}

export interface FormSingleSelectField extends IFormField<"single-select"> {
    options: SelectOption[];
    lg?: number;
    multi?: boolean;
    return_array?: boolean;
}

export type FormFruitImagesField = IFormField<"fruit-images">;

export interface FormInlineFormField extends IFormField<"inline-form"> {
    fields: FormField[];
    description?: string;
    lg?: number;
}

export type FormEmailField = IFormField<"email">;

export interface FormObjectListField extends IFormField<"object-list"> {
    fields: FormField[];
    getItemTitle: (item: any) => string;
    validate: (item: any) => boolean;
    disabled: boolean;
}

export interface FormTextListField extends IFormField<"text-list"> {
    field: FormTextField | FormEmailField;
}

export type FormField =
    | FormDateRadioButtonField
    | FormSelectManagedField
    | FormTextField
    | FormNumberField
    | FormRadioManagedField
    | FormQRCodeScannerField
    | FormSingleSelectField
    | FormRadioField
    | FormImageRadioButtonsField
    | FormCheckboxManagedField
    | FormLayerSelect
    | FormLayerSelectMulti
    | FormFruitImagesField
    | FormInlineFormField
    | FormEmailField
    | FormObjectListField
    | FormTextListField;

export type BarcodeFilter = {
    filter: (result: string) => string | null;
    formatsToSupport: Html5QrcodeSupportedFormats[];
} | ((result: string) => string | null);

export interface BaseCheckLocation {
    text?: string;
    value?: string;
    flow?: string[];
    inline_form?: string[]; // same as flow but without the first step (device results)
    fruit_form?: FormField[]; // new approach to inline form
    fruit_types?: string[]; // TODO: explicitly set type in fruit_types
    summary_fruit_fields?: string[]; // TODO: refactor to new field definition
    show_atron_color?: boolean;
    show_atron_results_bottom_summary?: boolean;
    meta_form_summary?: object[];
    meta_form_layer?: FormField[];
    pre_check_form?: FormField[];
    visual_check_form?: FormField[];
    device_result_action?: string | boolean;
    only_show_latest_check_on_child?: boolean; // only show latest check on child layer in calender overview
    show_pressure_prediction?: boolean;
    show_atron_weight?: boolean;
    required_images?: RequiredImages[];
    check_summary?: any; // upper header containing a summary of the check (layer info, aggregated check info, etc)
    show_latest_check?: { // define fields to show on the latest check in layer fields
        fields: Field[] | boolean
    };
    calibration_stage?: LAYER_STAGE; // From which calibration stage should the calibration be used either RIPENING or PRODUCTION
    required_sample_size?: number; // required sample size for this location
    require_another_check?: string; // require another check before running this check
    show_external_defects?: boolean;
    advance_ripening_classes_form?: string[];
    submit_instead_of_start_check?: boolean;
}

export interface CalibrationSettings {
    calibration_stage: LAYER_STAGE;
    min_fruit?: number;
    required_sample_size: number;
    device_result_action: string;
}
export interface CheckLocation extends BaseCheckLocation {
    text: string;
    is_hidden?: boolean;
    is_invalid?: boolean; // this location does not match with check.location (a fallback is used)
    is_manual?: boolean; // is this a manual check location (without device)
    allocate_stage?: LAYER_STAGE;
    value: string;
    flow: string[];
    summary_fruit_fields: string[]; // TODO: refactor to new field definition
    meta_form_advance_ripening?: FormField[];
    advance_ripening_start_form?: FormField[];
    pressure_calibration_settings?: CalibrationSettings;
    /**
     * If set, this function will be called to check if the user is allowed to create a check on this location
     * within the current state of the layer
     */
    // check_guard?: (layer:Layer) => {
    //     pass: boolean;
    //     message: string;
    // };
}

export interface FormOption {
    value: string;
    label: string;
}
// interface FruitOptions {
//     value: string;
//     label: string;
// }

interface RequiredImages {
    type: string;
    label: string | any;

}

interface PDFIntakeReport {
    external_defects_title?: string,
    internal_defects_title?: string,
    logo_name?: string,
    children_table_fields: Field[],
    children_check_summary_meta_fields?: Field[],
    children_check_external_defects?: Field[],
    children_check_internal_defects?: Field[],
    children_check_major_external_defects?: Field[],
    children_check_minor_external_defects?: Field[],
    children_check_unacceptable_defects?: Field[],
    check_meta_fields?: Field[]
    show_graphs?: boolean,
    pallet_meta_fields?: Field[] // only for ART
}
export interface LayerTypeConfig {
    text: string;
    value: LAYER_TYPE;
    view_strategy: string; // TODO: clean up, Not in use
    parent_layer_type?: boolean | LAYER_TYPE
    children_layer_type?: boolean | LAYER_TYPE
    disabled?: boolean; // disable the radio button for creation of this layer type
    meta_form?: object[]
    meta_display: Field[]
    // checkLocations: CheckLocation[];
    label_format?: string; // add restriction to layer creation
    invalid_hint?: string;

    show_edit_button?: boolean; // show edit button on left side of layer index
    show_link_layer_button?: boolean | string; // show link layer button on left side of layer index, pass string to define next action
    start_action?: string; // action to start something on layer index #TODO: create enum here
    show_images?: RequiredImages[]; // show images on layer index
    pdf_intake_report?: PDFIntakeReport// config for pdf intake report
    get_pdf_report?: (layer: Layer, report_type: string) => PDFIntakeReport | undefined; // get pdf report config based on layer
    special_actions?: object[] // special check button for top right of layer index
    show_allocate_button?: boolean; // show allocate button on layer index
    pdf_advance_ripening_report?: PDFIntakeReport; // hide allocate button on layer index
    is_creatable?: boolean; // is layer type selectable on creation
    hide_sub_pallets?: boolean; // hide sub pallets


    show_checks?: {
        view: VIEW,
        filter_field?: string
        filter_location?: LAYER_STAGE | string
        card_type?: CHECK_CARD_TYPE
        only_with_fruit?: boolean
    },
    show_children?: {
        view?: VIEW,
        fields: Field[]
        title?: string
        filter_field?: string
        filter_location?: LAYER_STAGE | string
    }
    show_ripening_progress?: {
        view: VIEW,
        field?: Field | boolean
        title?: string
        filter_field?: string
        filter_location?: LAYER_STAGE | string
    } | false
    show_ripening_progress_firmness_history_table?: {
        view: VIEW,
        title?: string
        filter_field?: string
        filter_location?: LAYER_STAGE | string
    } | false
    show_metric_cards?: false | null | { key: string, label: string }[];
    show_ripening_progress_pressure_history_table?: boolean
    show_general_notes?: boolean
    show_lab_check?: {
        title?: string
    };
    arrival_check_meta_display?: Field[];
}

interface Page {
    [key: string]: string;
}

export interface ViewStrategy {
    title: string;
    description: string;
    view_strategy: VIEW;
    layer_type: LAYER_TYPE | null;
    overview_fields?: Field[]; // If you want to enable specific field per view strategy
}
export interface Stage {
    text: string;
    value: LAYER_STAGE;
    layer_type?: LAYER_TYPE | null;
    layer_overview_fields?: Field[]; // Replace with the actual type if not an array of strings
    view_strategy: VIEW;
    view_strategies?: ViewStrategy[] | false;
    hide_color_code?: boolean;
    status_color?: string;
    status_letter?: string;
    overview_filter_form?: Field[];
    download_csv_fields?: Field[];
}


interface RipeningStage {
    gte?: boolean | number;
    lt?: number | boolean;
    gt?: boolean | number;
    lte?: number | boolean;
    label: string;
    color: string;
}

interface RequiredImage {
    type: string;
    label: string;
}

// export interface RootConfigType {
//     fruit_types: ConfigItemType[]
//     check_locations: ConfigItemType[]
//     layer_types: ConfigItemType[]
//     pdf_reports: ConfigItemType[]
//     stages: ConfigItemType[]

// }

export interface RootConfigType {
    currentPage?: string | undefined;
    home_page: string;
    download_csv_fields?: Field[];
    overview_filter_form?: object[];
    fruit_types: any
    check_locations: any
    layer_types: any
    pdf_reports: any
    stages: any
    get_fruit_type(fruit_type: string): FruitConfig;
    get_fruit_type_external_colors(pallet: any): ColorConfig[] | false;
    get_fruit_type_internal_colors(pallet: any): ColorConfig[] | false;
    default_fruit_type: string;
    required_fruit_images?: RequiredImage[];
    required_check_images?: RequiredImage[];
    plu?: boolean;// Use to add tenant form options on the the tenant config. should be refactored to a more generic solution
    countCategory: any; // Adjust this type based on the actual countCategory function/structure
    has_purchase_order_number: boolean;
    default_manual: boolean;
    can_do_manual_check: boolean;
    summary_collapsed: boolean;
    ripening_target_options: boolean;
    uses_bulk_edit_flow: boolean;
    show_atron_pressure_range_kg: boolean;
    indicate_soft_avocado_at_internal: boolean;
    indicate_quality_at_defects: boolean;
    suggest_color_stage: boolean;
    default_location: string;
    summary_like_question?: boolean | string; // A string to add above the flagging buttons in the check summary
    strict_check_size: boolean;
    underweight?: object[];
    research_mode: boolean;
    bakker: boolean;
    invalidate_20x_firmness: boolean;
    show_device_toggle: boolean;
    // get_overview_fields(filter: LayerFilter): Field[]; // get columns for the overview screens based on current filter (stage and layer_type)
    pages: Page;
    lang: { [key: string]: string };
    next(layer: Layer, check: Pick<Check, "location" | "is_manual">): Page | false;
    // after_device_step(layer: Layer, check:Pick<Check, "location"|"is_manual">): Page | false;
    prev(layer: Layer, check: Pick<Check, "location" | "is_manual">): Page | false;
    title(): string;
    // TODO: rename to base_location_config
    default_location_config: BaseCheckLocation;
    get_location(layer: Layer, check: Pick<Check, "location" | "is_manual">): CheckLocation | undefined;
    get_locations(layer: Layer): CheckLocation[];
    filter_location_fields(rawLocation: any, filter: object): CheckLocation; // TODO: define type for rawLocation and displayFilter
    get_stage(stage: null | string | undefined): Stage;
    get_layer_config(layer: Layer): LayerTypeConfig;
    get_raw_layer_config(layer_type: string | undefined): any; // TODO make type for raw layer config, with unfiltered fields
    get_layer_pdf_reports(layer_type: string): any
    get_pdf_report(layer: Layer, report_type: string): PDFIntakeReport | undefined;
    translate_atron_color_to_manual_color_stage(atron_color: number): string | number;
    // TODO clear this up: this is ripening stage bases on pressure
    ripening_stages: RipeningStage[];
    // TODO clear this up: this is ripening stage bases on median firmness from device
    ripening_stages_firmness: RipeningStage[];
    // TODO clear this up: this is a list of categories for pressure (how is this different then ripening_stage ?)
    pressure_classes: RipeningStage[];
    // TODO clear this up: this is also the the same as ripening_stage ?
    ripening_stages_pressure?: RipeningStage[];
    dry_matter_classes?: RipeningStage[];
    kg_to_ripening_stage(kg: number): RipeningStage;
    firmness_to_ripening_stage(median_firmness: number): RipeningStage;
    firmness_to_display_value: (firmness: number) => number | string;
    headerSecondaryLinks: string[];
    headerMainLinks: string[];
    barcode_filter: BarcodeFilter;
    use_barcode_scanner?: boolean;
    is_admin?: boolean;
    optionsYesNo?: FormOption[]; // TODO: can be removed ?
    is_location_calibrated(location: CheckLocation, layer: Layer): boolean
    get_onchange_callback(string): any;
    onchange_callbacks: { [key: string]: any };

    getOptions?: (tenant_id: string) => void,
    getConfigs?: (tenant_id: string) => void
    mock_signals?: {
        color: string,
        title: string,
        description: string,
        url: string,
        flag: string,
    }[];
}

export enum UserRole {
    TENANT_ADMIN = "TenantAdmin"
}
