import { useRoutes } from "react-router-dom";
import NoMatch404 from "./NoMatch";
import Check from "./scenes/AddItem";
import Login from "./scenes/Auth/Login";
import Logout from "./scenes/Auth/Logout";
import { RequireAuth } from "./scenes/Auth/RequireAuth";
import DeviceManagment from "./scenes/DeviceManagment";
import DeviceConfiguration from "./scenes/DeviceManagment/DeviceManagement";
import LabellingStart from "./scenes/Labelling";
import LabellingManualSession from "./scenes/Labelling/ManualLabelSession";
import LabellingNewSession from "./scenes/Labelling/NewLabelling";
import LayerIndex from "./scenes/Layers/Layer/LayerIndex";
import PDFAdvanceRipeningReport from "./scenes/Layers/Layer/Report/PDFAdvanceRipeningReport";
import PDFCheckReport from "./scenes/Layers/Layer/Report/PDFCheckReport";
import PDFIntakeReport from "./scenes/Layers/Layer/Report/PDFIntakeReport";
import LayerStart from "./scenes/Layers/LayerStart";
import Mailbox from "./scenes/Mailbox/Mailbox";
import AdminPage from "./scenes/Admin/AdminPage";
import PackingListList from "./scenes/PackingList/PackingListList";
import PowerBI from "./scenes/PowerBi/PowerBI";
import Settings from "./scenes/Settings";
import Tenants from "./scenes/Tenants";
import TenantEditConfig from "./scenes/Tenants/TenantEditConfig";
import TenantEditDefectsForm from "./scenes/Tenants/TenantEditDefectsForm";
import TenantEditForm from "./scenes/Tenants/TenantEditForm";
import TenantEditOptionsForm from "./scenes/Tenants/TenantEditOptionsForm";
import WasteIndex from "./scenes/Waste";

const items = [
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/logout",
        element: <Logout />,
    },
    {
        path: "/",
        element: <RequireAuth fullscreen={true} element={<LayerStart />} />,
    },
    {
        path: "/layer",
        children: [
            {
                index: true,
                element: <RequireAuth fullscreen={true} element={<LayerStart />} />,
            },
            {
                path: ":layer_id",
                element: <RequireAuth fullscreen={true} element={<LayerIndex />} />,
            },
            {
                path: ":layer_id/report/intake",
                element: <RequireAuth fullscreen={true} disableRefresh={true} element={<PDFIntakeReport />} />,
            },
            {
                path: ":layer_id/report/advance_ripening",
                element: <RequireAuth fullscreen={true} disableRefresh={true} element={<PDFAdvanceRipeningReport />} />,
            },
            {
                path: ":layer_id/add-check/:test_id/:action/:avocado_id",
                element: <RequireAuth fullscreen={true} element={<Check />} />,
            },
            {
                path: ":layer_id/add-check/:test_id/report",
                element: <RequireAuth fullscreen={true} disableRefresh={true} element={<PDFCheckReport />} />,
            },
            {
                path: ":layer_id/add-check/:test_id/:action",
                element: <RequireAuth fullscreen={true} element={<Check />} />,
            },
            {
                path: ":layer_id/add-check/:test_id",
                element: <RequireAuth fullscreen={true} element={<Check />} />,
            },
            {
                path: ":layer_id/action/:action",
                element: <RequireAuth fullscreen={true} element={<LayerIndex />} />,
            },
        ],
    },
    {
        path: "/settings",
        element: <RequireAuth element={<Settings />} />,
    },
    {
        path: "/tenants",
        children: [
            {
                index: true,
                element: <RequireAuth element={<Tenants />} />,
            },
            {
                path: ":tenant_id",
                element: <RequireAuth element={<TenantEditForm />} />,
            },
            {
                path: ":tenant_id/form-field-options/defects", // * alias for defects
                element: <RequireAuth element={<TenantEditDefectsForm />} />,
            },
            {
                path: ":tenant_id/form-field-options/:field",
                element: <RequireAuth element={<TenantEditOptionsForm />} />,
            },
            {
                path: ":tenant_id/defects/:fruit_type",
                element: <RequireAuth element={<TenantEditDefectsForm />} />,
            },
            {
                path: ":tenant_id/config/:config_id",
                element: <RequireAuth element={<TenantEditConfig />} />,
            },
        ],
    },
    {
        path: "/labelling",
        children: [
            {
                index: true,
                element: <RequireAuth element={<LabellingStart />} />,

            },
            {
                path: "new",
                element: <RequireAuth element={<LabellingNewSession />} />,
            },
            {
                path: ":session_id",
                element: <RequireAuth element={<LabellingManualSession />} />,
            },

        ],
    },
    {
        path: "/device-managment",
        children: [
            {
                index: true,
                element: <RequireAuth element={<DeviceManagment />} />,
            },
            {
                path: ":device_uid/view/:view",
                element: <RequireAuth element={<DeviceConfiguration />} />,
            },
            {
                path: ":device_uid",
                element: <RequireAuth element={<DeviceConfiguration />} />,
            },
        ],
    },
    {
        path: "/power-bi",
        children: [{
            index: true,
            element: <RequireAuth element={<PowerBI />} />,
        }],
    },
    {
        path: "/waste",
        children: [{
            index: true,
            element: <RequireAuth element={<WasteIndex />} />,
        }],
    },
    {
        path: "/packing-list",
        children: [{
            index: true,
            element: <RequireAuth element={<PackingListList />} />,
        }],
    },
    {
        path: "/mailbox",
        children: [{
            index: true,
            element: <RequireAuth element={<Mailbox />} />,
        }],
    },
    {
        path: "/admin/*",
        element: <RequireAuth element={<AdminPage />} />,
    },
    {
        path: "*",
        element: <NoMatch404 />
    },
];

export default function MainContent() {
    const router = useRoutes(items);
    return router;
}
