/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";
import { listWaste } from "../../../actions/Waste/actions";
import { Loading } from "../../Helper/Loading";
import { PaginationRow } from "../../Helper/PaginationRow";

export default function WasteList() {
    const isLoading = useSelector((state) => state.waste.isLoading);

    if (isLoading) {
        return <Loading/>;
    }
    return <div>
        <WasteTable />
    </div>;
}

function TH({ children }) {
    return <td className="text-muted text-uppercase text-start text-nowrap">{children}</td>;
}

TH.propTypes = {
    children: PropTypes.any,
};

function TD({ children }) {
    return <td className="text-start text-uppercase text-nowrap">{children}</td>;
}

TD.propTypes = {
    children: PropTypes.any,
};
export function WasteTable() {
    const user = useSelector((state) => state.auth.user);
    const waste_items = useSelector((state) => state.waste.items);
    const config = useConfig();
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.waste.filter);

    if (!waste_items) {
        return null;
    }
    const updateLimit = (limit) => {
        dispatch(listWaste({ ...filter, offset: 0, limit }));
    };

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(listWaste({ ...filter, offset }));
        }
    };
    const fields = config.waste_overview_fields;
    // sometimes needed when switching tenant
    if (!fields) {
        return null;
    }
    const values = (p) => fields.map((i, index) => <TD key={index}>{i.value(p, config, user)}</TD>);

    const header = () => fields.map((i, index) => <TH key={index}>{i.label}</TH>);

    return <div className="pt-4">
        <div css={css`min-height: 20rem;`}>
            <Table size="sm" responsive={true} borderless={true}>
                <thead>
                    <tr>{header()}</tr>
                </thead>
                <tbody>
                    {waste_items.results.map((i, index) => <tr key={index} className="clickable" title={i.id} >{values(i)}</tr>)}
                </tbody>

            </Table >
        </div>
        <PaginationRow results={waste_items.results || []} totalCount={waste_items?.count || 0} onPageChanged={onPageChanged} filter={filter} updateLimit={updateLimit} />
    </div>;
}
