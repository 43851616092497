import { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { toast } from "../../../../utils/toast";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { validateEmail } from "../../../../utils/tools";
import { getAvosApiClient } from "../../../../utils/useAvosApiHook";
import { FormField, FormObjectListField } from "../../../../actions/Tenants/config/constantsTyped";
import MetaForm from "../../../Forms/MetaForm";
import { FileImportModalProps, FileImportModal, FileType } from "../../../Helper/FileImportModal";
import { useManagedOptionsHook } from "../../../Forms/useManagedOptionsHook";
import { validateRecipient } from "../../utils";
import { EmailRecipients } from "../../../../actions/Admin/reducer";


const emailRecipientFields: FormField[] = [
    {
        label: "Display name",
        name: "displayName",
        type: "text"
    },
    {
        label: "Email Address",
        name: "address",
        type: "email",
        validate: validateEmail
    },
];

const getRecipientTitle = (recipient) => recipient?.displayName || recipient?.address || "Recipient to be configured";

export const validateEmailRecipients = (email_recipients) => "to" in email_recipients && "cc" in email_recipients && "bcc" in email_recipients
    && email_recipients.to.every(validateRecipient) && email_recipients.cc.every(validateRecipient) && email_recipients.bcc.every(validateRecipient);
export const validateSupplierEmailRecipientsMap = (supplierEmailRecipientsMap) => Object.keys(supplierEmailRecipientsMap).every((supplier) => validateEmailRecipients(supplierEmailRecipientsMap[supplier]));

export interface SupplierEmailRecipientsMap {
    [supplier: string]: EmailRecipients;
}

export interface State {
    supplier: string;
    email_recipients: EmailRecipients;
    apply_to_all_supplier: boolean;
}

const createEmailRecipientsField = (name: string, disabled: boolean): FormObjectListField => ({
    label: name,
    name: name.toLowerCase(),
    type: "object-list",
    fields: emailRecipientFields,
    getItemTitle: getRecipientTitle,
    validate: validateRecipient,
    disabled
});

const getForm = (state: State): FormField[] => [
    {
        label: "Apply to all supplier",
        name: "apply_to_all_supplier",
        type: "radio",
        options: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ],
        default_value: false,
    },
    {
        label: "Supplier",
        name: "supplier",
        type: "select-managed",
        multi: false,
        disabled: state.apply_to_all_supplier,
    },
    {
        label: "Email recipients",
        name: "email_recipients",
        type: "inline-form",
        disabled: !state.supplier && !state.apply_to_all_supplier,
        fields: [
            createEmailRecipientsField("To", !state.supplier && !state.apply_to_all_supplier),
            createEmailRecipientsField("Cc", !state.supplier && !state.apply_to_all_supplier),
            createEmailRecipientsField("Bcc", !state.supplier && !state.apply_to_all_supplier)
        ]
    }];

const BulkImportModal = (props: Pick<FileImportModalProps<SupplierEmailRecipientsMap>, "open" | "onClose" | "onChange"> & { supplierOptions: { label: string, value: string }[]; }) => {
    const title = "Bulk import email recipients / Delete all existing email recipients";
    const description = <div>
        <div><p>This will replace all email recipients with the data from the file.</p></div>
        <div><p>Use asterix (*) as a wildcard for the supplier name.Only applies to csv files.</p></div>
        <div><p>File format for json:</p></div>
        <div><pre>
            {`{"to": [{   
    "address":"john.doe@experiencedata.nl",
    "displayName":"John Doe"
}], "cc": [], "bcc": [] }`}
        </pre></div>
    </div>;
    const parseFile = (fileType: FileType, fileContent: string) => {
        const handlers = {
            "text/csv": (content) => {
                /*
                * Parse the rows into email recipients
                * @example csv content:
                * Supplier,Recipient Name,Recipient Email Address,to/cc/bcc
                * *,,mpukqaripening@missionproduce.com,cc
                * *,,mpuksourcing@missionproduce.com,cc
                * *,,mpuktechnical@missionproduce.com,cc
                * Agroatlantic,,alacayo@missionproduce.com,to
                */
                // Split the content into rows
                try {
                    const rows = content.split("\n");
                    // Remove the first row if the third column is not an email address
                    if (rows.length > 0) {
                        const columns = rows[0].split(",");
                        if (columns.length < 3 || !validateEmail(columns[2])) {
                            rows.shift();
                        }
                    }
                    // Create an object with the supplier as key and the email recipients as value
                    const supplierRecipientsMapWithSupplierLabel = {} as SupplierEmailRecipientsMap;

                    // Iterate over the rows and add the email recipients to the object
                    rows.forEach((row) => {
                        const columns = row.split(",").map((x) => x.trim());
                        if (columns.length < 3) {
                            return;
                        }
                        const supplier = columns[0];
                        const emailRecipient = {
                            displayName: columns[1] || columns[2],
                            address: columns[2]
                        };
                        const type = columns[3].toLowerCase();
                        if (!validateRecipient(emailRecipient)) {
                            return;
                        }
                        if (!supplierRecipientsMapWithSupplierLabel[supplier]) {
                            supplierRecipientsMapWithSupplierLabel[supplier] = { to: [], cc: [], bcc: [] };
                        }
                        supplierRecipientsMapWithSupplierLabel[supplier][type].push(emailRecipient);
                    });

                    // Replace the supplier label with the supplier value
                    const { supplierOptions } = props;
                    supplierOptions.push({ label: "*", value: "*" });
                    const supplierRecipientsMapWithSupplierValue = {} as SupplierEmailRecipientsMap;
                    const unknownSuppliers = [] as string[];
                    Object.keys(supplierRecipientsMapWithSupplierLabel).forEach((supplier) => {
                        const supplierOption = supplierOptions.find((option) => option.label === supplier);
                        if (supplierOption) {
                            supplierRecipientsMapWithSupplierValue[supplierOption.value] = supplierRecipientsMapWithSupplierLabel[supplier];
                        } else {
                            unknownSuppliers.push(supplier);
                        }
                    });
                    if (unknownSuppliers.length > 0) {
                        toast.error(`There are unknown suppliers: ${unknownSuppliers.join(", ")}`);
                    }
                    let message: any = "";
                    if (unknownSuppliers.length > 0) {
                        message = <div>
                            <p>There are unknown suppliers in the file. Do you still want to import the email recipients?</p>
                            <p>Unknown suppliers:</p>
                            <ul>
                                {unknownSuppliers.map((supplier) => <li key={supplier}>{supplier}</li>)}
                            </ul>
                        </div>;
                    }
                    return { data: supplierRecipientsMapWithSupplierValue, message };
                } catch (error) {
                    const message = "Invalid email recipients csv data format.";
                    toast.error(message);
                    return { message };
                }
            },
            "application/json": (content) => {
                let obj = JSON.parse(content);
                const message = "Invalid email recipients json data format.";
                if (!validateSupplierEmailRecipientsMap(obj)) {
                    obj = undefined;
                    toast.error(message);
                }
                return { data: obj, message };
            },
        };
        const handler = handlers[fileType];
        if (!handler) {
            throw new Error(`Unsupported file type: ${fileType}`);
        }
        return handler(fileContent);
    };
    return <FileImportModal<SupplierEmailRecipientsMap> {...props} visual={{ title, description }} accept={["application/json", "text/csv"]} parseFile={parseFile} />;
};

export default function EmailRecipientsForm({ tenant_id }) {
    const initialEmailRecipients: EmailRecipients = { to: [], cc: [], bcc: [] };
    const config = useConfig();
    const [state, setState] = useState({
        supplier: "",
        email_recipients: initialEmailRecipients,
        apply_to_all_supplier: false,
    });
    const email_recipients_callback = (resp) => {
        const email_recipients = resp.data?.email_recipients || initialEmailRecipients;
        setState({ ...state, email_recipients });
    };
    const [isModalOpen, setModalOpen] = useState(false);

    const supplierOptions = useManagedOptionsHook({ object: state, name: "supplier" });

    const loadEmailRecipients = () => {
        if (state.supplier || state.apply_to_all_supplier) {
            getAvosApiClient(`/supplier/${state.apply_to_all_supplier ? "*" : state.supplier}?tenant_id=${tenant_id}`)
                .then(email_recipients_callback);
        }
    };

    useEffect(() => {
        loadEmailRecipients();
    }, [state.supplier, state.apply_to_all_supplier]);
    const onChange = (emailRecipients: EmailRecipients) => {
        getAvosApiClient(`/supplier/email_recipients`, {
            method: "POST",
            data: {
                email_recipients: emailRecipients,
                tenant_id,
                supplier_name: state.apply_to_all_supplier ? "*" : state.supplier
            },
        }).then(email_recipients_callback);
    };
    const setValue = (name, value) => {
        setState({ ...state, [name]: value });
        if (name === "email_recipients" && validateEmailRecipients(value)) {
            onChange(value);
        }
    };
    const onBulkChange = (supplierEmailRecipientsMap: SupplierEmailRecipientsMap) => {
        getAvosApiClient(`/supplier/email_recipients/bulk`, {
            method: "POST",
            data: {
                supplierEmailRecipientsMap,
                tenant_id,
            },
        }, {
            onSuccess: {
                message: "Email recipients imported successfully",
                showMessage: true
            }
        }).then(loadEmailRecipients);
    };
    return <>
        <Row className="justify-content-center">
            <Col lg="12" >
                <MetaForm meta={getForm(state)} setValue={setValue} object={state} config={config} />
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col lg="12" >
                <Button color="primary" outline onClick={() => setModalOpen(true)}>Import email recipients from file</Button>
                <BulkImportModal open={isModalOpen} onClose={() => setModalOpen(false)} onChange={onBulkChange} supplierOptions={supplierOptions} />
            </Col>
        </Row>
    </>;
}
